<template>
  <div v-if="!profileRequesting" style="height: 100vh" class="d-flex flex-column">
    <div style="background-color: #000000" class="container flex-grow-1 pb-5">
      <div class="head mb-5">
        <div class="justify-content-center row">
          <div class="col-lg-7">
            <img src="@/assets/registor_header_2.png" />
          </div>
        </div>
      </div>
      <div class="justify-content-center row">
        <div class="col-lg-7">
          <h4>填寫會員資料</h4>
          <h6 style="color: #ff0000">必填欄位*</h6>
          <div class="row my-4">
            <div class="col">
              <h6>姓<span class="required">*</span></h6>
              <b-form-input
                id="lastName"
                type="text"
                v-model="$v.lastName.$model"
                :state="validateState('lastName')"
              />
              <b-form-invalid-feedback id="lastName">必填</b-form-invalid-feedback>
            </div>
            <div class="col">
              <h6>名<span class="required">*</span></h6>
              <b-form-input
                id="firstName"
                type="text"
                v-model="$v.firstName.$model"
                :state="validateState('firstName')"
              />
              <b-form-invalid-feedback id="firstName">必填</b-form-invalid-feedback>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <h6>手機號碼</h6>
              <div class="row align-items-center">
                <div class="col">
                  <b-form-input v-model="profile.mobile" type="number" disabled />
                  <b-badge class="verify_badge">已驗證</b-badge>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <h6>身分證字號<span class="required">*</span></h6>
              <b-form-input
                id="nationalId"
                type="text"
                v-model="$v.nationalId.$model"
                :state="validateState('nationalId')"
              />
              <b-form-invalid-feedback id="nationalId">必填</b-form-invalid-feedback>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <h6>生日<span class="required">*</span></h6>
              <div class="row">
                <div class="col-6 pr-0">
                  <b-input-group>
                    <b-form-select
                      v-model="birthyearType"
                      :options="birthyearTypeOptions"
                    ></b-form-select>
                    <b-form-select
                      v-show="birthyearType === 'bc'"
                      v-model="$v.birthyear.$model"
                      :options="birthyearBCOption"
                    ></b-form-select>
                    <b-form-select
                      v-show="birthyearType === 'roc'"
                      v-model="$v.birthyear.$model"
                      :options="birthyearROCOption"
                    ></b-form-select>
                    <!-- <b-form-input
                      placeholder="年"
                      v-model="$v.birthyear.$model"
                      :state="validateState('birthyear')"
                    ></b-form-input> -->
                  </b-input-group>
                </div>
                <div class="col-6">
                  <b-input-group>
                    <b-form-select
                      v-model="$v.birthmonth.$model"
                      :options="birthMonthOption"
                    ></b-form-select>
                    <!-- <b-form-input
                      placeholder="月"
                      v-model="$v.birthmonth.$model"
                      :state="validateState('birthmonth')"
                    ></b-form-input> -->
                    <b-form-select
                      v-model="$v.birthdate.$model"
                      :options="birthDateOption"
                    ></b-form-select>
                    <!-- <b-form-input
                      placeholder="DD"
                      v-model="$v.birthdate.$model"
                      :state="validateState('birthdate')"
                    ></b-form-input> -->
                  </b-input-group>
                </div>
                <b-form-invalid-feedback id="birthday">必填</b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <h6>車牌</h6>
              <b-form-input id="plateNumber" type="text" v-model="$v.plateNumber.$model" />
              <b-form-invalid-feedback id="plateNumber">必填</b-form-invalid-feedback>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <h6>Email<span class="required">*</span></h6>
              <b-form-input
                id="email"
                type="email"
                v-model="$v.email.$model"
                :state="validateState('email')"
              />
              <b-form-invalid-feedback id="email">必填</b-form-invalid-feedback>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <h6>通訊地址<span class="required">*</span></h6>
              <div class="row mb-3">
                <div class="col-6 pr-1">
                  <b-form-select
                    :options="city_options"
                    v-model="$v.city.$model"
                    :state="validateState('city')"
                    @change="onCityChange"
                  />
                </div>
                <div class="col-6 pl-1">
                  <div class="row align-items-center">
                    <div class="col">
                      <b-form-select
                        v-model="$v.area.$model"
                        :state="validateState('area')"
                        :options="area_options"
                        @change="onAreaChange"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <b-form-input
                    id="step1_address"
                    v-model="$v.address.$model"
                    :state="validateState('address')"
                    type="text"
                    placeholder="輸入段/路/街/巷/號"
                  />
                  <b-form-invalid-feedback id="step1_address">必填</b-form-invalid-feedback>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col">
              <label>
                <input type="checkbox" v-model="agreeTerm" /> 我同意PAMO<a
                  href="https://www.pamolaw.com/terms/"
                  >服務使用條款</a
                >
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex fixed-bottom">
      <!-- <div class="flex-fill" @click="logout">
        <Button name="登出" />
      </div> -->
      <div :class="{ disabled: !agreeTerm }" class="flex-fill" @click="onSubmit">
        <Button name="下一步" class="highlight" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, between, numeric } from 'vuelidate/lib/validators';
import { auth, plan, record } from '@/mixins';
import Button from '@/elements/Button.vue';

export default {
  mixins: [auth, plan, validationMixin, record],
  name: 'UpdateUser',
  validations: {
    firstName: { required },
    lastName: { required },
    birthyear: {
      required,
      numeric,
      between: between(0, 2000),
    },
    birthmonth: {
      required,
      numeric,
      between: between(0, 11),
    },
    birthdate: {
      required,
      numeric,
      between: between(0, 31),
    },
    birthday: { required },
    nationalId: { required },
    plateNumber: {},
    email: { required },
    address: { required },
    area: { required },
    city: { required },
  },
  computed: {
    ...mapState({
      profileRequesting: (state) => state.auth.profileRequesting,
      profile: (state) => state.auth.profile,
      partnerAuthData: (state) => state.auth.partnerAuthData,
      city_options: (state) => state.records.city,
      area_options: (state) => state.records.area,
      requestingCreateRecord: (state) => state.records.requestingCreateRecord,
    }),
  },
  components: {
    Button,
  },
  data() {
    return {
      step: 0,
      firstName: '',
      lastName: '',
      email: '',
      city: '1',
      area: '1',
      address: '',
      birthyearTypeOptions: [
        { value: 'roc', text: '民國' },
        { value: 'bc', text: '西元' },
      ],
      birthyearBCOption: [
        { value: null, text: '年', disabled: true },
        { value: '1936', text: '1936' },
        { value: '1937', text: '1937' },
        { value: '1938', text: '1938' },
        { value: '1939', text: '1929' },
        { value: '1940', text: '1940' },
        { value: '1941', text: '1941' },
        { value: '1942', text: '1942' },
        { value: '1943', text: '1943' },
        { value: '1944', text: '1944' },
        { value: '1945', text: '1945' },
        { value: '1946', text: '1946' },
        { value: '1947', text: '1947' },
        { value: '1948', text: '1948' },
        { value: '1949', text: '1949' },
        { value: '1950', text: '1950' },
        { value: '1951', text: '1951' },
        { value: '1952', text: '1952' },
        { value: '1953', text: '1953' },
        { value: '1954', text: '1954' },
        { value: '1955', text: '1955' },
        { value: '1956', text: '1956' },
        { value: '1957', text: '1957' },
        { value: '1958', text: '1958' },
        { value: '1959', text: '1959' },
        { value: '1960', text: '1960' },
        { value: '1961', text: '1961' },
        { value: '1962', text: '1962' },
        { value: '1963', text: '1963' },
        { value: '1964', text: '1964' },
        { value: '1965', text: '1965' },
        { value: '1966', text: '1966' },
        { value: '1967', text: '1967' },
        { value: '1968', text: '1968' },
        { value: '1969', text: '1969' },
        { value: '1970', text: '1970' },
        { value: '1971', text: '1971' },
        { value: '1972', text: '1972' },
        { value: '1973', text: '1973' },
        { value: '1974', text: '1974' },
        { value: '1975', text: '1975' },
        { value: '1976', text: '1976' },
        { value: '1977', text: '1977' },
        { value: '1978', text: '1978' },
        { value: '1979', text: '1979' },
        { value: '1980', text: '1980' },
        { value: '1981', text: '1981' },
        { value: '1982', text: '1982' },
        { value: '1983', text: '1983' },
        { value: '1984', text: '1984' },
        { value: '1985', text: '1985' },
        { value: '1986', text: '1986' },
        { value: '1987', text: '1987' },
        { value: '1988', text: '1988' },
        { value: '1989', text: '1989' },
        { value: '1990', text: '1990' },
        { value: '1991', text: '1991' },
        { value: '1992', text: '1992' },
        { value: '1993', text: '1993' },
        { value: '1994', text: '1994' },
        { value: '1995', text: '1995' },
        { value: '1996', text: '1996' },
        { value: '1997', text: '1997' },
        { value: '1998', text: '1998' },
        { value: '1999', text: '1999' },
        { value: '2000', text: '2000' },
        { value: '2001', text: '2001' },
        { value: '2002', text: '2002' },
        { value: '2003', text: '2003' },
        { value: '2004', text: '2004' },
        { value: '2005', text: '2005' },
      ],
      birthyearROCOption: [
        { value: null, text: '年', disabled: true },
        { value: '25', text: '25' },
        { value: '26', text: '26' },
        { value: '27', text: '27' },
        { value: '28', text: '28' },
        { value: '29', text: '29' },
        { value: '30', text: '30' },
        { value: '31', text: '31' },
        { value: '32', text: '32' },
        { value: '33', text: '33' },
        { value: '34', text: '34' },
        { value: '35', text: '35' },
        { value: '36', text: '36' },
        { value: '37', text: '37' },
        { value: '38', text: '38' },
        { value: '39', text: '39' },
        { value: '40', text: '40' },
        { value: '41', text: '41' },
        { value: '42', text: '42' },
        { value: '43', text: '43' },
        { value: '44', text: '44' },
        { value: '45', text: '45' },
        { value: '46', text: '46' },
        { value: '47', text: '47' },
        { value: '48', text: '48' },
        { value: '49', text: '49' },
        { value: '50', text: '50' },
        { value: '51', text: '51' },
        { value: '52', text: '52' },
        { value: '53', text: '53' },
        { value: '54', text: '54' },
        { value: '55', text: '55' },
        { value: '56', text: '56' },
        { value: '57', text: '57' },
        { value: '58', text: '58' },
        { value: '59', text: '59' },
        { value: '60', text: '60' },
        { value: '61', text: '61' },
        { value: '62', text: '62' },
        { value: '63', text: '63' },
        { value: '64', text: '64' },
        { value: '65', text: '65' },
        { value: '66', text: '66' },
        { value: '67', text: '67' },
        { value: '68', text: '68' },
        { value: '69', text: '69' },
        { value: '70', text: '70' },
        { value: '71', text: '71' },
        { value: '72', text: '72' },
        { value: '73', text: '73' },
        { value: '74', text: '74' },
        { value: '75', text: '75' },
        { value: '76', text: '76' },
        { value: '77', text: '77' },
        { value: '78', text: '78' },
        { value: '79', text: '79' },
        { value: '80', text: '80' },
        { value: '81', text: '81' },
        { value: '82', text: '82' },
        { value: '83', text: '83' },
        { value: '84', text: '84' },
        { value: '85', text: '85' },
        { value: '86', text: '86' },
        { value: '87', text: '87' },
        { value: '88', text: '88' },
        { value: '89', text: '89' },
        { value: '90', text: '90' },
        { value: '91', text: '91' },
        { value: '92', text: '92' },
        { value: '93', text: '93' },
        { value: '94', text: '94' },
      ],
      birthMonthOption: [
        { value: null, text: '月', disabled: true },
        { value: '0', text: '1' },
        { value: '1', text: '2' },
        { value: '2', text: '3' },
        { value: '3', text: '4' },
        { value: '4', text: '5' },
        { value: '5', text: '6' },
        { value: '6', text: '7' },
        { value: '7', text: '8' },
        { value: '8', text: '9' },
        { value: '9', text: '10' },
        { value: '10', text: '11' },
        { value: '11', text: '12' },
      ],
      birthDateOption: [
        { value: null, text: '日', disabled: true },
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
        { value: '5', text: '5' },
        { value: '6', text: '6' },
        { value: '7', text: '7' },
        { value: '8', text: '8' },
        { value: '9', text: '9' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' },
        { value: '13', text: '13' },
        { value: '14', text: '14' },
        { value: '15', text: '15' },
        { value: '16', text: '16' },
        { value: '17', text: '17' },
        { value: '18', text: '18' },
        { value: '19', text: '19' },
        { value: '20', text: '20' },
        { value: '21', text: '21' },
        { value: '22', text: '22' },
        { value: '23', text: '23' },
        { value: '24', text: '24' },
        { value: '25', text: '25' },
        { value: '26', text: '26' },
        { value: '27', text: '27' },
        { value: '28', text: '28' },
        { value: '29', text: '29' },
        { value: '30', text: '30' },
        { value: '31', text: '31' },
      ],
      birthyearType: 'roc',
      birthyear: null,
      birthmonth: null,
      birthdate: null,
      birthday: null,
      nationalId: '',
      agreeTerm: false,
      plateNumber: '',
      end: {
        link: '/',
        action: '下一步',
      },
    };
  },
  mounted() {
    this.fetchCityAndArea();
    if (this.$route.query.auth) {
      this.partnerAuth({
        authToken: this.$route.query.auth,
      });
    }
  },
  watch: {
    partnerAuthData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const { firstName, lastName, nationalId, birth, plateNumber, email } =
            this.partnerAuthData.userData;
          this.firstName = firstName;
          this.lastName = lastName;
          this.nationalId = nationalId;
          this.birthday = birth;
          this.plateNumber = plateNumber;
          this.email = email;
        }
      },
    },
    birthyearType: 'convertBirthday',
    birthyear: 'convertBirthday',
    birthmonth: 'convertBirthday',
    birthdate: 'convertBirthday',
  },
  methods: {
    convertBirthday() {
      if (this.birthyearType === 'roc') {
        this.birthday = new Date(this.birthyear, this.birthmonth, this.birthdate);
        const y = new Date(this.birthday).getFullYear();
        const m = new Date(this.birthday).getMonth();
        const d = new Date(this.birthday).getDate();
        this.birthday = new Date(y + 11, m, d);
      } else {
        this.birthday = new Date(this.birthyear, this.birthmonth, this.birthdate);
        const m = new Date(this.birthday).getMonth();
        const d = new Date(this.birthday).getDate();
        this.birthday = new Date(this.birthyear, m, d);
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    logout() {
      this.requestLogout();
    },
    onCityChange(val) {
      this.fetchAreaByCity({
        cityId: val,
        onSuccess: (res) => {
          this.area = res.data[0].id;
        },
      });
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        console.log('ss', this.$v.$anyError);
        return;
      }

      const { firstName, lastName, email, birthday, nationalId, plateNumber, address, city, area } =
        this;

      this.updateUser({
        id: this.profile.id,
        firstName,
        lastName,
        email,
        birthday,
        nationalId,
        plateNumber,
        address,
        zipCode: this.area_options.find((v) => v.value === area).zipCode,
        area: this.area_options.find((v) => v.value === area).text,
        city: this.city_options.find((v) => v.value === city).text,
        onSuccess: () => {
          if (
            this.partnerAuthData &&
            this.partnerAuthData.userData.licenseKey &&
            (this.profile.licenses || []).length === 0
          ) {
            // 自動綁定序號
            this.addPlan({
              licenseKey: this.partnerAuthData.userData.licenseKey,
              onSuccess: () => {
                this.$router.replace('/payment/order');
              },
              onFailed: () => {
                alert('綁定序號時發生錯誤，請聯繫客服。');
              },
            });
          } else {
            this.$router.replace('/payment/order');
          }
        },
        onFailed: (err) => {
          switch (err.response.data.apiErrorCode) {
            case 'ACCOUNT_ALREADY_BOUND':
              alert('此帳號已完成註冊步驟，如有疑問，請聯繫客服。');
              break;
            case 'DUPLICATED_NATIONAL_ID':
              alert('此身分證字號已被其他帳號使用。');
              break;
            case 'DUPLICATED_EMAIL':
              alert('此E-mail位置已被其他帳號使用。');
              break;
            default:
              alert('發生錯誤，請再試一次');
              break;
          }
        },
      });
    },
  },
};
</script>
<style>
.required {
  color: #ff0000;
  font-size: 20px;
}

.disabled {
  pointer-events: none;
}

.disabled > div {
  background-color: #463c2f !important;
}

.verify_badge {
  position: absolute;
  top: 10px;
  right: 25px;
}
</style>
